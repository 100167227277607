:root {
  --themeColor: #4180fc;
}

.siteWidth {
  max-width: 1440px;
  margin: auto;
  padding: 0px 70px;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}

@media (width < 768px) {
  .imageLine::before {
    height: 365px !important;
  }

  .siteWidth {
    padding: 0px 20px;
  }

  .footerLinks li {
    font-size: 14px;
  }

}

@media (width < 900px) {
  .recentProject {
    grid-area: 1;
  }

  .logoWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
}

@media (width > 900px) {
  .blogSidebar {
    position: sticky;
    top: 97px;
    height: fit-content;
  }

  .recentProject img {
    min-width: 300px;
    max-width: 550px;
  }
}

@media (900px < width < 1200px) {
  .imageLine::before {
    height: 370px !important;
  }
}

#appHeader {
  transition: all 0.1s ease-in-out;
}

.next,
.previous {
  display: none;
}

.paginationWrapper a svg {
  color: grey;
}

.pageRoot {
  display: flex;
}


ul[role="navigation"] {
  display: flex;
  list-style-type: none;
  padding-left: 0px;
  align-items: center;
}

ul[role="navigation"] li {
  margin: 0px 10px;
  padding: 5px 0px;
  text-decoration: none;
  border: 1px solid #58b79a;
  cursor: pointer;
  border-radius: 50%;
}

ul[role="navigation"] li a {
  padding: 5px 12px;
  font-weight: 700;
}

ul[role="navigation"] li.break {
  border: 0px solid black;
}

ul[role="navigation"] li.selected {
  border: 1px solid #58b79a;
  background-color: #58b79a;
}

ul[role="navigation"] li:first-child,
ul[role="navigation"] li:last-child {
  border: 0px solid rgb(0, 0, 0);
}

ul[role="navigation"] li:first-child a,
ul[role="navigation"] li:last-child a {
  padding: 0px;
  margin-top: 0px;
}

.newsletterEmailInput label {
  padding: 0px 20px;
}

.letterInputParent .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiFilledInput-root::before,
.letterInputParent .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiFilledInput-root::before {
  border-bottom: 0px solid silver !important;
}

.newsletterEmailInput input {
  background: #ffffff;
  box-shadow: 1px 2px 3px #dbdbdb;
}

.newsletterEmailInput label+div::before {
  border-bottom: none;
}

.newsletterEmailInput label .css-1ff8729-MuiInputBase-root-MuiFilledInput-root:hover {
  border-bottom: none;
}

.ani {
  display: inline-block;
  width: 0px;
  overflow: hidden;
  animation: loding 1s ease-in-out infinite;
  height: 100%;
  position: relative;
  top: 11px;
  color: #2167ff;
  padding-left: 4px;
}

@keyframes loding {
  0% {
    width: 0px;
  }

  30% {
    width: 11px;
  }

  65% {
    width: 22px;
  }

  95% {
    width: 33px;
  }
}

body {
  scroll-behavior: smooth;
}

body {
  font-family: open sans !important;
}

.letsTalkFields .MuiInputBase-root {
  background-color: #ffffff;
}

.menuBtn {
  font-weight: 600;
  font-size: 14px;
}

.menuBtn.navActive {
  color: var(--themeColor);
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper {
  background-color: #ffffff;
}

.teamImage:hover {
  transform: scale(1.1);
}

.work:nth-child(odd) {
  background-color: #f9f9f9;
}

.contactUpperInputWrapper .MuiFormControl-root.MuiTextField-root {
  margin-bottom: 5px;
}

.contactLowerInputWrapper input {
  height: 60px;
}

.contactUpperInputWrapper .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiFilledInput-root::before,
.contactLowerInputWrapper .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiFilledInput-root::before {
  border: 1px solid rgb(20, 20, 20) !important;
  background-color: #ffffff;
  height: 0px;
}

.contactUpperInputWrapper input,
.contactLowerInputWrapper input,
.contactLowerInputWrapper .MuiInputBase-root {
  background-color: #ffffff !important;
}

.contactLowerInputWrapper .MuiInputBase-root.MuiFilledInput-root {
  height: 100%;
  margin-bottom: 5px;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
  border: 1px solid rgb(205 201 201) !important;
  box-shadow: 1px 0px 3px #bfbfbf73;
  border-radius: 3px;
}

.pageWidth {
  max-width: 1920px;
  margin: auto;
}

.agencyServices img {
  width: 100%;
  height: 100%;
  filter: brightness(0.5);
}

.weDo img {
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  height: auto;
}

.center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.leftContent::before {
  content: "''";
  text-align: center;
  font-size: 95px;
  color: #ffffff;
  font-weight: 300;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  display: block;
  background-color: #262626;
  position: relative;
  top: -45px;
  box-shadow: 1px 2px 6px rgb(142 74 252);
}

.imageLine {
  position: relative;
  z-index: 3;
}

.imageLine::before {
  content: '';
  width: 300px;
  height: 400px;
  position: absolute;
  top: -50px;
  left: 0px;
  border: 10px solid #ffae1e;
  z-index: -1;
}

.appBar .MuiBox-root.css-19kzrtu {
  display: none;
}

#navbarNav li {
  font-size: 14px;
  font-weight: 500;
  font-family: poppins;
  margin-left: 30px;
}

@media screen and (max-width:540px) {
  #footerBlog {
    margin: 35px 0px !important;
  }

  .logoWrapper {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width:899px) {

  .leftContent::before {
    top: -28px;
  }

  .menuBtn {
    margin: 0px 0px 0px 0px !important;
    padding: 3px;
    font-weight: 600;
    font-size: 17px;
  }

  .headerDiv {
    display: none;
  }

}

@media screen and (min-width:900px) {
  .homeBanner {
    margin-top: 0px;
  }

  .menuBtn {
    color: #000000;
    padding: 0px 5px 5px 9px;
  }

}

@media screen and (min-width:900px) and (max-width:1025px) {
  .menuBtn {
    margin-left: 20px !important;
  }
}

@media screen and (max-width:1024px) {
  .siteLogo {
    margin-left: 20px;
  }

  .skillPill {
    height: 190px;
  }
}

.seoPageContentRoot p {
  margin: 0px;
}

/*main seo page form css */
.seoPageContentRoot .seofrom .elementor-form input {
  border: 1px solid #ccc !important;
  font-family: 'Open Sans' !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  margin-top: 5px;
  height: 44px !important;
  border-radius: 0px;
  margin-right: 0;
}

.seoPageContentRoot .seofrom .elementor-form select {
  border: 1px solid #ccc !important;
  margin-top: 5px;
  font-size: 13px;
  font-family: 'Open Sans' !important;
  font-weight: 300 !important;
  height: 70px !important;
  border-radius: 0px;
}

.seoPageContentRoot .seofrom .elementor-form select option,
.seoPageContentRoot .seofrom .elementor-form input::placeholder {
  font-family: 'Open Sans' !important;
  font-weight: 300 !important;

}

.seoPageContentRoot .seofrom .elementor-form textarea {
  border: 1px solid #ccc !important;
  border-radius: 0px;
  margin-right: 0;
}

.seoPageContentRoot .seofrom .elementor-form [type="checkbox"] {
  height: inherit !important;
}

.seoPageContentRoot .elementor-field-option label {
  color: #7a7a7a !important;
  font-family: 'Open Sans' !important;
  font-weight: 300 !important;
}

.seoPageContentRoot .seofrom .elementor-form button {
  cursor: pointer;
}

.seoPageContentRoot .seofrom .elementor-form .elementor-field-option {
  padding-bottom: 5px;
}

.seoPageContentRoot .seofrom .elementor-form [type="checkbox"] {
  margin-top: 0;
  margin-right: 5px;
}

.seoPageContentRoot .seofrom .elementor-form #form-field-selectform {
  height: 44px !important;
}

/*main seo page form css end */

.reviewImage {
  background: black;
  padding: 5px 15px;
  border-radius: 3px;
}

.appBar {
  border-bottom: 1px solid rgb(237 228 228);
}

.logoText {
  font-size: 35px;
  margin: 0px;
  color: #000000;
  font-weight: 700;
}

.servicesUl {
  margin: 30px 0px;
}

.servicesUl li {
  margin-bottom: 10px;
}

.wedoRight img {
  border-radius: 5px;
}

.categoryTile {
  margin: 3px 0px 13px 0;
}

.skillPill {
  border-radius: 5px;
}

.categoryTile a {
  margin-right: 10px;
  border-radius: 16px;
  padding: 2px 11px;
  font-weight: 700;
}

.categoryTile a:nth-child(1) {
  border: 2px solid #ffae1e;
}

.categoryTile a:nth-child(2) {
  border: 2px solid #2aa6df;
}

.categoryTile a:nth-child(3) {
  border: 2px solid #8bdf2a;
}

.titleBorder {
  width: 50%;
}

.titleBorder span:first-child {
  width: 45%;
  height: 5px;
  display: inline-block;
  background-color: rgb(255, 141, 75);
}

.titleBorder span:nth-child(2) {
  width: 35%;
  height: 5px;
  display: inline-block;
  background-color: rgb(67, 217, 223);
}

.titleBorder span:nth-child(3) {
  width: 20%;
  height: 5px;
  display: inline-block;
  background-color: rgb(174, 0, 255);
}

.serviceRight {
  background-position: center;
  background-size: cover;
}

/* For browsers that support the text-stroke property */
.logoText {
  color: transparent;
  /* Set the text color */
  -webkit-text-stroke: 1.5px black;
  /* Set the stroke width and color for WebKit browsers */
  text-stroke: 2px black;
  /* Set the stroke width and color for other browsers */
}

.servicesWrapperRight>div {
  opacity: 1;
  transition: all 0.2s ease-in;
  margin-top: 100px;
}

.servicesWrapperRight>div p,
.servicesWrapperRight>div ul li {
  font-size: 17px;
}

@media (width>=1300px) {
  .servicesWrapperRight>div {
    padding-top: 100px;
  }
}

/* Example CSS for the carousel navigation buttons */
.slick-prev,
.slick-next {
  position: absolute !important;
  bottom: -14px !important;
  top: auto !important;
  z-index: 1;
  cursor: pointer;
}

.slick-prev:before,
.slick-next:before {
  font-size: 37px !important;
  padding: 5.2px 2.1px 0px 3px;
  color: #1a66fbd4 !important;
  background-color: hsl(219, 100%, 95%) !important;
  border-radius: 50%;
  opacity: 1 !important;
}

.slick-prev {
  left: auto !important;
  right: 130px;
}

.servicesUl li {
  list-style-type: none;
  position: relative;
}

.servicesUl li::before {
  content: '';
  background-image: url('./images/tick.png');
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 4px;
  left: -25px;
  height: 17px;
  width: 17px;
}

.slick-next {
  right: 80px !important;
}


.roomDetailsTable {
  width: 100%;
}

.roomDetailsTable,
.roomDetailsTable th,
.roomDetailsTable td {
  border: 1px solid rgb(187 187 187);
  border-collapse: collapse;
  text-align: left;
  padding: 8px 12px;
  font-size: 14px;
  line-height: normal;
}

.allRooms>div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}

.pgWrapper {
  width: 100%;
}

.tabActive {
  background: tomato;
  color: #fff;
}

@media screen and (max-width:767px) {
  .allRooms>div {
    grid-template-columns: 1fr;
  }

  .pgWrapper {
    padding-top: 50px;
  }
}


.addRoom {
  max-width: 600px;
  margin: auto;
  margin-top: 40px;
}

.blogImageWrapper img {
  max-height: 150px;
}



/* ///////// css for allguest page ///////////// */
.custom-header-css {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 15px 0px;
  background: #fff;
  border-bottom: 1px solid #ddd;
}

.custom-navbar-css ul li a:hover {
  border-radius: 0 !important;
}

.nav-link.active {
  position: relative;
  background: #f0f8ff;
}

.dropdown-toggle.user-name {
  cursor: pointer;
}

.add-cutomer-section .fa-plus {
  font-size: 13px;
  top: -1px;
  position: relative;
}

.nav-link.active:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #1b6ef3;
}

.customer-table-css input[type=checkbox],
.customer-table-css input[type=radio] {
  width: 14px;
  height: 14px;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.customer-table-css tr:last-child {
  border-bottom: 1px solid transparent;
}

.main-content-css {
  padding-top: 115px;
  background-color: #87CEEB;
  min-height: 100vh;
  padding-bottom: 15px;
}

.search-input:focus,
.search-icon-custom:focus {
  box-shadow: none;
  border-color: #ddd;
}

.search-icon-custom {
  background: #fff;
}

.btn {
  font-size: 15px;
}

#relationship-select-small,
#dueDate-select-small {
  padding-top: 7px;
  padding-bottom: 6px;
}

.add-customer-btn,
.search-input,
.search-icon-custom,
#dropdownMenuButton {
  padding: 0px 11px;
  min-height: 32px;
  font-size: 14px;
  border-radius: 5px;
  background: #fff;
}

.add-customer-btn:hover,
.add-customer-btn:active {
  color: #0d6efd !important;
  background-color: #fff !important;
}

.customer-table-css .fa-plus {
  font-size: 13px;
  position: relative;
  top: -1px;
}

#dropdownMenuButton,
.add-customer-btn {
  color: #0d6efd;
  border: 1px solid #ddd !important;
}

.search-input:focus {
  border: 1px solid #3378ff;
  border-right: 0 !important;
}

.search-input:focus~.input-group-append .search-icon-custom {
  border: 1px solid #3378ff !important;
  border-left: 0 !important;
}

.search-icon-custom {
  border-radius: 0 0.375rem 0.375rem 0;
}

.customer-table-css tbody tr td {
  font-size: 12px;
}

.customerTable td {
  padding: 5px 8px 2px 8px;
}

.customerTable td>div,
.customerTable td>span {
  max-height: 35px;
  overflow: hidden;
  display: inline-block;
}

.customer-table-css thead tr th:first-child,
.customer-table-css tbody tr td:first-child {
  padding-left: 17px;
}

.customer-table-css thead tr th {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  text-align: left !important;
  font-size: 15px;
  ;
}

.customer-pagination .page-item .page-link:focus {
  box-shadow: none;
}

.dropdown-menu li:first-child:hover .dropdown-item {
  border-radius: 0.375rem 0.375rem 0 0;
}

.dropdown-menu li:last-child:hover .dropdown-item {
  border-radius: 0 0 0.375rem 0.375rem;
}

.dropdown-menu li:last-child .dropdown-item {
  border-bottom: 0 !important;
}

.dropdown-menu li .dropdown-item {
  border-bottom: 1px solid #ddd !important;
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  gap: 8px;
}

.dropdown-menu {
  padding: 0;
}

.add-cutomer-section .dropdown-menu.addCol {
  left: -30px !important;
  top: 3px !important;
  max-height: 388px;
  overflow: hidden;
  overflow-y: scroll;
}

/* width */
.add-cutomer-section .dropdown-menu.addCol::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.add-cutomer-section .dropdown-menu.addCol::-webkit-scrollbar-track {
  background: #d4d1d1;
}

/* Handle */
.add-cutomer-section .dropdown-menu.addCol::-webkit-scrollbar-thumb {
  background: #b3b3b3;
  border-radius: 5px;
}

/* Handle on hover */
.add-cutomer-section .dropdown-menu.addCol::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.searchParentWrapper {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 10px;
}


.add-cutomer-section {
  border-radius: .375rem .375rem 0 0;
}

.serial-no {
  width: 45px;
  ;
}

.customer-table-css thead th {
  white-space: nowrap;
}

.sortable-header .fa-sort-down {
  position: relative;
  top: -2px;
}

.sortable-header .fa-sort-up {
  position: relative;
  top: 6px;
}

.user-menu {
  position: relative;
  cursor: pointer;
}

.user-menu .user-name {
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-dropdown {
  display: none;
  position: absolute;
  right: 0;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  border-radius: 4px;
  overflow: hidden;
}

.user-menu:hover .user-dropdown {
  display: block;
}

.user-dropdown .dropdown-item {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
}

.user-dropdown .dropdown-item:hover {
  background-color: #ddd;
}

.navbar-nav .nav-link {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.navbar-nav .nav-link:hover {
  background-color: #ddd;
  border-radius: 4px;
}

.customer-table-css th {
  position: relative;
  background: white;
}

.add-cutomer-section .fa-user {
  background: #ff8f66;
  padding: 6px 8px;
  border-radius: 5px;
  color: #fff;
}

.react-resizable {
  position: initial !important
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(0) !important;
  display: block;
  height: 47px !important;
  width: max-content !important;
}

.react-resizable-handle-e {
  margin-top: -23px !important;
}

.sortable-header {
  cursor: pointer;
  position: absolute;
  right: 12px;
}

.no-of-item {
  font-weight: 500;
  color: #858383;
}

/* .truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px; 
} */

.leadWrapper {
  width: 235px;
  float: right;
  align-items: center;
  display: flex;
}

.leadWrapper .label-title {
  width: 160px;
}

.customer-details-grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 15px;
}

.first-column-box,
.second-column-box,
.third-column-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.detail-tab-box {
  position: absolute;
  right: 0;
}

.detail-tab-box h5 {
  width: max-content;
  margin-bottom: 0;
  border-bottom: 3px solid blue;
  padding-bottom: 10px;
}

.label-title {
  font-size: 14px;
  color: grey;
  font-weight: 400;
  padding-bottom: 5px;
}

.label-value {
  width: 100% !important;
}

.comment-text {
  font-size: 14px;
  line-height: normal;
  color: #000000;
}

.comment-timestamp {
  font-size: 12px;
  color: grey;
}

.comment-box {
  max-height: 300px;
  overflow: auto;
}

.fa-arrow-left {
  cursor: pointer;
}

.status-card-list ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  border: 1px solid #ddd;
  border-radius: 25px;
  margin-bottom: 0;
}

.status-card-list ul li {
  border-right: 1px solid #ddd;
  padding: 5px 30px;
  position: relative;
}

.status-card-list ul li:last-child {
  border-right: 0;
}

input:focus,
textarea:focus,
select:focus {
  box-shadow: none !important;
}

select {
  height: 38px;
}

select,
textarea,
input {
  font-size: 15px !important;
  appearance: auto !important;
}

.custom-header-css .dropdown-menu.show li a {
  text-decoration: none;
}

.dropdown-menu li .dropdown-item {
  background: none;
  color: #000000 !important;
}

.dropdown-menu li:hover .dropdown-item {
  background: none;
  font-weight: 600;
}

.login-box-width {
  width: 350px;
  max-width: 100%;
}




.notification-popup {
  position: fixed;
  top: 5px;
  font-size: 14px;
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  visibility: visible;
  opacity: 1;
  background-color: var(--bg-color, #ffffff);
  color: var(--text-color, #000000);
}

.progress-bar {
  height: 4px;
  width: 0%;
  background-color: #007bff;
  margin-top: 5px;
}



.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
}

.customerTable td {
  word-break: break-all;
}

.customerTable {
  height: calc(100vh - 170px);
  /* overflow: scroll; */
}

/* width */
.customerTable::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.customer-details-grid textarea::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.customer-details-grid textarea::-webkit-scrollbar-track,
.customerTable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.customer-details-grid textarea::-webkit-scrollbar-thumb,
.customerTable::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, rgb(234, 231, 231), rgb(190, 189, 189));
  border-radius: 10px;
}

/* Handle on hover */
.customer-details-grid textarea::-webkit-scrollbar-thumb:hover,
.customerTable::-webkit-scrollbar-thumb:hover {
  background: #cbcbcb;
}

.customerTable thead {
  position: sticky;
  top: -1px;
  z-index: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.label-value:focus-visible {
  outline: none;
}

.label-value:read-only {
  border: none;
}

select.label-value {
  border: 1px solid #000 !important;
  height: 28px;
}

select.label-value:disabled {
  border: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.importCompany {
  max-width: 233px;
  border: 1px solid silver;
  margin-right: 10px;
  border-radius: 5px;
  padding: 3px;
  height: 100%;
}

.filterBar {
  display: grid !important;
  grid-template-columns: 1fr 3fr 5fr;
  gap: 20px;
  margin-bottom: 10px;
}